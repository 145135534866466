import IMAGES_CDN_PATH from '@vend/images-ui'

export const PLACEHOLDER_IMAGE = `${IMAGES_CDN_PATH}products/placeholder.svg`

export const getProductImage = (productImage?: string) => {
  return productImage && !productImage.includes('no-image-white-thumb.png')
    ? productImage
    : PLACEHOLDER_IMAGE
}
// resolveProductImage decides whether to use a product's imageThumbnailURL, skuImageThumbnailURL, or the placeholder image
export const resolveProductImage = (
  imageThumbnailURL?: string,
  skuImageThumbnailURL?: string
) => {
  if (
    skuImageThumbnailURL &&
    !skuImageThumbnailURL.includes('no-image-white-thumb.png')
  ) {
    return skuImageThumbnailURL
  } else if (
    imageThumbnailURL &&
    !imageThumbnailURL.includes('no-image-white-thumb.png')
  ) {
    return imageThumbnailURL
  }
  return PLACEHOLDER_IMAGE
}

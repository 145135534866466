import { getXsrfToken } from '../api/getXsrfToken'
import { VEND_NS } from './vendNamespace'

const fetchOptions: RequestInit = {
  credentials: 'include',
  redirect: 'error',
  headers: {
    'x-xsrf-token': getXsrfToken() || '',
  },
}

/**
 * Fetches the current user but does not issue a redirect if the user is not authenticated.
 * In general you don't want to use this function, use `getUser` instead.
 * This is for certain cases where we don't know if the user is authenticated and we don't want to redirect.
 */
export const dangerouslyGetUser = async ({ refetch = false } = {}) => {
  if (VEND_NS.user && !refetch) {
    return VEND_NS.user
  }
  const response = fetch('/api/2.0/user', fetchOptions)
  if ((await response).ok) {
    VEND_NS.userPromise = (await response).json()
  }

  const userApiResult = await VEND_NS.userPromise
  VEND_NS.user = userApiResult?.data
  return VEND_NS.user
}

/**
 * Fetches the current retailer but does not issue a redirect if the user is not authenticated.
 * In general you don't want to use this function, use `getRetailer` instead.
 * This is for certain cases where we don't know if the user is authenticated and we don't want to redirect.
 */
export const dangerouslyGetRetailer = async ({ refetch = false } = {}) => {
  if (VEND_NS.retailer && !refetch) {
    return VEND_NS.retailer
  }
  const response = fetch('/api/2.0/retailer', fetchOptions)
  if ((await response).ok) {
    VEND_NS.retailerPromise = (await response).json()
  }

  const retailerApiResult = await VEND_NS.retailerPromise
  VEND_NS.retailer = retailerApiResult?.data
  return VEND_NS.retailer
}
